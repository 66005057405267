<template>
  <Card>
    <template #title>
      {{ $t("applications.uploadSigned") }} ({{$t('global.pdfSmaller')}})
    </template>

    <template #content>
      <!-- <div class="upload">
        <div class="grid flex-column">
          <div class="col">
            <img
              class="uploadIcon"
              src="/layout/images/upload.png"
              alt="Upload application"
            />
          </div>
          <div class="col">
            {{ $t("applications.uploadFiles") }}
          </div>
          <div class="col">
            <Button
              :label="$t('applications.uploadSignedPdf')"
              class="mr-2 mb-2"
            />
          </div>
        </div>
      </div> -->
      <FileUpload
        name="file"
        :multiple="false"
        :maxFileSize="$fileSize"
        :showCancelButton="false"
        :customUpload="true"
        @uploader="uploadFile"
        :invalidFileSizeMessage="`{0}: ${$t('global.invalidFileSize')} {1}.`"
        :invalidFileTypeMessage="`{0}: ${$t('global.invalidFileType')} {1}.`"
        :chooseLabel="$t('global.choose')"
        :uploadLabel="$t('global.upload')"
        @select="checkFiles"
        @remove="checkFiles"
      >
        <template #empty>
          <div
            class="flex flex-column align-items-center justify-content-center h-13rem border-solid border-100 mb-5"
          >
            <div class="flex align-items-center justify-content-center h-auto">
              <span
                alt="Upload application"
                class="mdi mdi-96px mdi-orange mdi-cloud-upload-outline"
              ></span>
            </div>
            <div class="flex align-items-center justify-content-center">
              {{ $t("applications.uploadFiles") }}
            </div>
          </div>
        </template>
      </FileUpload>
    </template>

    <template #footer>
      <div class="text-right">
        <Button
          :label="$t('global.cancel')"
          @click="cancel"
          class="p-button-danger"
        />
        <Button
          :label="$t('global.submit')"
          autofocus
          :loading="loadingButton"
          iconPos="right"
          :disabled="disableSubmit ? 'disabled' : false"
          @click="submit"
          style="margin-left: 1%; margin-right: 1%"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Upload and sign",
  emits: ["next-page"],
  data() {
    return {
      disableSubmit: true,
      loadingButton: false,
      buttonElement: ""
    };
  },
  computed: {
    ...mapGetters(["getApplicationPdf", "clientInfo", "userInfo"]),
  },
  mounted() {
    this.$nextTick(() => {
          this.buttonElement = document.querySelector(
            ".p-fileupload-buttonbar button"
          );
          if (this.buttonElement) {
            this.buttonElement.style.display = "none";
          }
        });
  },
  methods: {
     checkFiles(event) {
      if (event.files.length > 0) {
        this.disableSubmit = false;
      } else {
        this.disableSubmit = true;
      }
    },
    cancel() {
      this.$router.push({ name: "applications" });
    },
    async submit() {
      //       const result = await this.v$.$validate();
      this.buttonElement.click();
      this.loadingButton = true;
      await this.$store.dispatch("updateStatusApplication", {
        id: this.getApplicationPdf.id,
        status: "new",
        version: this.getApplicationPdf.version,
      }).then(() => {
        this.$action('application-submit',{applicationId: this.getApplicationPdf.id}, 'application');
      })
      await this.$store.dispatch("clientInfo", {});
      this.loadingButton = false;
      this.$emit("next-page", { pageIndex: 3, value: 100 });
      this.$router.push("/applications");
    },
    async uploadFile(data) {
      try {
        await this.$store.dispatch("uploadFile", {
          file: data.files[0],
          type: "application",
          id: this.getApplicationPdf.id,
        }).then(() => {
          this.$action("application-upload-signed", { applicationId: this.getApplicationPdf.id });
          this.$notification(
            this.$t("applications.createApplication"),
            this.$t("applications.pdfUploadSuccessful")
          );
        })
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadIcon {
  height: 6%;
  width: 6%;
  margin-bottom: 5%;

  // user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.col {
  text-align: center;
}

.upload {
  // padding-top: 5%;
  // padding-bottom: 2%;
  border-style: solid;
  border-color: #f9f9f9;
}
</style>
